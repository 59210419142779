import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from '../components/SEOAgency/Banner'

import WhyChooseUs from '../components/SEOAgency/WhyChooseUs'

import AnalysisForm from '../components/SEOAgency/AnalysisForm'

const SeoAgency = () => {
    return (
        <Layout>
            <Navbar />
            <Banner />
            
            <WhyChooseUs />
           
          
            <AnalysisForm />
           
            <Footer />
        </Layout>
    )
}

export default SeoAgency
